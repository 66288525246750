.App {
  text-align: center;
  font-size: larger;
  padding: 5px;
}

.name {
  width: 300px;
  padding: 5px;
}
.content {
  width: 400px;
  height: 200px;
  padding: 5px;
}

.add {
  width: 320px;
  height: 35px;
  margin-top: 10px;
}